.dark-theme{
  // primary
  --cms-primary: #e20082;
  --cms-primary-inner-text: white;
  --cms-primary-hover: #ff6782;
  --cms-primary-inner-text-hover: white;

  // secondary
  --cms-secondary: #526e93;
  --cms-secondary-inner-text: white;
  --cms-secondary-hover: #728794;
  --cms-secondary-inner-text-hover: white;

  // third
  --cms-third: #c4c4c4;
  --cms-third-inner-text: white;
  --cms-third-hover: #7c7c7c;
  --cms-third-inner-text-hover: white;

  // use cases
  --cms-background:linear-gradient(176deg, #0C1123 4.59%, #010102 100.63%);
  --cms-glass:rgba(225, 239, 226, 0.10);
  --cms-text-color: #d3d3d3;
  --cms-disable-color: #a09c9c;
  --cms-confirm-btn: #40b400;
  --cms-confirm-btn-inner: #fff;
  --cms-confirm-btn-hover: #68b934;
  --cms-confirm-btn-inner-hover: #fff;
  --cms-delete-btn: #ff0082;
  --cms-delete-btn-inner: #ffffff;
  --cms-delete-btn-hover: #ff61ae;
  --cms-delete-btn-inner-hover: #ffffff;

  //overall colors
  --cms-white: #252525;
  --cms-black:#ffffff;
  --cms-light-gray:#3e3e3e;
  --cms-dark-gray: #5D738E;
  --cms-green: #40b400;
  --cms-blue: #00bdff;
  --cms-red: #ff0000;
  --cms-transparent: transparent;
  --cms-yellow: #EBC337;

  //form colors
  --cms-form-border: transparent;
  --cms-form-background: #1D2236;

  // shadows
  --cms-shadow-a: rgba(0, 0, 0, 0.23);
  --cms-shadow-b: rgba(0,0,0,0.05);

  // gradients
  --cms-primary-gradient-start: #E13E3B;
  --cms-primary-gradient-end: #E87E09;
  --cms-primary-gradient: linear-gradient(81deg, #e20082 0%, #8f0054 100%);
  --cms-violet-gradient: linear-gradient(81deg, #3719AB 0%, #8539DB 100%);
}
