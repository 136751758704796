@import "../../styles/variables.scss";

.discountHeader{
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.couponContainer {    
    display: flex;
    flex-direction: row;
    width: 312px;
    height: 100px;
    background: linear-gradient(0deg, #00125930 19%, #1D2236 100%);
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: 15px;
    
}

.bump{
  mask:
  radial-gradient(circle 42px at -30px center ,#0000 100%,#000) center left,
  radial-gradient(circle 42px at 185px center,#0000 98%,#000) center right;
  
    mask-size: 50% 100%;
    mask-repeat: no-repeat;
}

.dataContainer {
    width: 100%;    
    display: flex;
    flex-direction: row;
    
    .imageContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 70px;        
    }

    .detailsContainer {        
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        justify-content: space-between;
        font-family: UberMoveRegular;
        font-size: 14px;
    }

    .detailsBottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .discount {
        font-size: 20px;
        font-family: UberMoveBold;
        color: #FF7C32;
    }

    .redeemCode {
        border: 1px dotted #4A617C;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 36px;
        width: 74px;
    }
}

.noDiscount{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    align-self: center;    
    margin: 30px;
    

    font-family: UberMoveBold;
    font-size: 22px;
    text-align: center;
    color: #697D95;
    line-height: 28px;
}

.generalPizza{
    width: 75px;
    height: 75px;
}