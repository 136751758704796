@import 'src/styles/variables';

.container {
	display: flex;
	position: fixed;
	overflow: hidden;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
}

.menu {
	position: absolute;
	width: 100%;
	height: 100%;
	background: linear-gradient(0deg, #001259d8 19%, #1D2236 100%);
	;
	left: -85%;
	animation-duration: 0.3s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-name: openMenu;
	animation-timing-function: linear;
}

.closeMenu {
	left: 0;
	animation-name: closeMenu;
}

@keyframes openMenu {
	0% {
		left: -100%;
	}

	100% {
		left: 0;
	}
}

@keyframes closeMenu {
	0% {
		left: 0;
	}

	100% {
		left: -100%
	}
}

.menuContainer {
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;

	.topContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		padding: 24px;

		.topSection {
			width: 125px;
			height: 120px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			div {
				display: flex;
				flex-direction: row;
				align-items: center;
				height: 24px;
			}

			span {
				font-family: UberMoveRegular;
				font-size: 16px;
				padding-left: 8px;
				color: #BAC7D5;
			}
		}
	}

	.bottomSection {
		display: flex;
		flex-direction: row;
		padding: 32px;
		
		div {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 24px;
		}

		span {
			font-family: UberMoveRegular;
			font-size: 16px;
			padding-left: 8px;
			color: #BAC7D5;
		}
	}
}