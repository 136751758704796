@import 'src/styles/variables';

.slideContainer{
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.onHide{
  opacity: 0;
}

.controls{
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  div{
    position: absolute;
    bottom: 1.5rem;
    left:1.5rem;
  }
}