@import "src/styles/variables";

.pattern{
  position: relative;
  height: 100%;
  width: 100%;
  img{
    width: 30%;
    position: absolute;
  }
  :nth-child(1){
    top:7%;
    left: 36%;
    transform: rotate(180deg);
  }
  :nth-child(2){
    top:15%;
    left: 51.5%;
    transform: rotate(-135deg);
  }
  :nth-child(3){
    top:31%;
    left: 57%;
    transform: rotate(-90deg);
  }
  :nth-child(4){
    top:47%;
    left: 49.5%;
    transform: rotate(-45deg);
  }
  :nth-child(5){
    top:53%;
    left: 33.5%;
  }
  :nth-child(6){
    top:45.5%;
    left: 17%;
    transform: rotate(45deg);
  }
  :nth-child(7){
    top:28.5%;
    left: 12%;
    transform: rotate(90deg);
  }
  :nth-child(8){
    top:12%;
    left: 19.5%;
    transform: rotate(135deg);
  }
}

.squarePattern{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  img{
    width: 25%;
    height: auto;
    display: block;
  }
}
