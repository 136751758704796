@import "src/styles/variables.scss";

.buyGiftHeader{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
}

.calendarButtons{width: 100%;
    display: flex;
    justify-content: center;

    div {
        width: 234px;
        display: flex;
        justify-content: space-between;
    }
}

.emailInputContainer{
    position: relative;
}

.buttonSend{
    
    width: 85px;
    
}

.calendarButtonOK{
    width: 126px;
    height: 44px;
}

.calendarButtonCancel{
    width: 92px;
    height: 44px;
}