@import 'src/styles/variables';

.container{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mainLabel{
  width: 100%;
}

.items{
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-flow: row wrap;
  column-gap: 20px;
  row-gap: 10px;
}