@import "src/styles/variables.scss";

.donateMain{
    flex-direction: column;
    background-image: url('../../../assets/images/donate.png');
    background-position: bottom;
    background-repeat: no-repeat;
    align-items: center;
    height: 100vh;
    justify-content: space-between!important;
}

.messageBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: #22201E;
    width: 332px;
    height: 187px;   
    padding: 17px;
    text-align: center;
    border-radius: 25px; 
}

.donateHeader{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
}

.calendarButtons{width: 100%;
    display: flex;
    justify-content: center;

    div {
        width: 234px;
        display: flex;
        justify-content: space-between;
    }
}

.buttonSave{
    width: 148px;
}

.calendarButtonOK{
    width: 126px;
    height: 44px;
}

.calendarButtonCancel{
    width: 92px;
    height: 44px;
}