@import "src/styles/variables";

@keyframes fadeIn {
  0% {
    background: rgba(0,0,0, 0);
  }
  100% {
    background: rgba(0,0,0, 0.5);
  }
}

@keyframes fadeOut {
  0% {
    background: rgba(0,0,0, 0.5);
  }
  100% {
    background: rgba(0,0,0, 0);
  }
}

@keyframes slideBottomToTop {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideTopToBottom {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.container {
  position: absolute;
  width:100vw;
  height: 100svh;
  background: rgba(0,0,0, 0.5);
  animation: 0.5s ease-out 0s 1 fadeIn;
}

.transparentContainer{
  background: rgba(0,0,0, 0)!important;
}

.containerFadeOut {
  background: rgba(0,0,0, 0)!important;
  animation: 0.3s ease-out 0s 1 fadeOut;
}

.sheet{
  position: absolute;
  max-height: 90%;
  width: 100%;
  bottom: 0;
  border-radius: 35px 35px 0 0;
  background: $white;
  padding: 0 1.5rem 1.5rem 1.5rem;
  animation: 0.5s ease-out 0s 1 slideBottomToTop;
}

.onClose{
  bottom: initial!important;
  top: 100%;
  animation: 0.3s ease-out 0s 1 slideTopToBottom;
}

.handle{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 35px;
  span{
    width: 54px;
    height: 2px;
    background: $form-border;
  }
}