@import "src/styles/variables";

.sliceUsage{
  display: flex;
  width:100%;
  justify-content: space-between;
  margin-top: 1rem;
  gap:0.5rem;
  .sliceUsageButton{
    display: flex;
    width: 100%;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    padding: 0.75rem;
  }
  :first-child{
   background: linear-gradient(180deg, rgba(7, 39, 152, 0.20) 0%, rgba(87, 87, 87, 0.00) 100%);
  }
  :nth-child(2), :nth-child(3){
    background: linear-gradient(180deg, rgba(175, 20, 25, 0.20) 28.68%, rgba(87, 87, 87, 0.00) 100%);
  }
  :last-child{
    background: linear-gradient(180deg, rgba(11, 84, 24, 0.20) 37.08%, rgba(87, 87, 87, 0.00) 100%);
  }
}
