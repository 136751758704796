@import "src/styles/variables";

.pattern{
  width: 60%;
  max-width: 300px;
}

.enlargedPatternContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 300px;
}

.enlargedPattern{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 300px;
}

.statics{
  flex: 1;
  display: flex;
  padding: 1rem;
  border-radius: 15px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: $glass;
  width: 100%;
  .staticsItem{
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-basis: 50%;
  }
}

.missed{
  display: flex;
  gap:10px;
  flex-direction: column;
  align-items: center;
  width: 40px;
  background: $glass;
  border-radius: 15px;
  padding: 0.5rem;
  flex: 0.125;
  img{
    width: 100%;
  }
}
