.container{
  
  display: flex;
  flex-direction: column;
  
  height: 361px;
  align-items: center;
  justify-content: space-around;
  background-color: #22201E;
  border-radius: 25px;
  padding: 17px;
  text-align: center;   

  img{
    width: 70px;
  }
}

.redeemCode{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-family: UberMoveBold;
  font-size: 32px;
  border: 2px dotted #275A4E;
  padding: 7px;
  min-width: 136px;
  max-width: 90%;
  width: fit-content;
  height: 48px;
  border-radius: 5px;
  color: #46833A;
}
