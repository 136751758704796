@import "./variables";

$colors : (
        primary:$primary-color,
        secondary:$secondary-color,
        third:$third-color,
        red:$red,
        green:$green,
        blue:$blue,
        white:$white,
        black:$black,
        yellow:$yellow,
        light-gray:$light-gray,
        mid-gray:$mid-gray,
        dark-gray:$dark-gray,
        primary-gradient:$primary-gradient,
        violet-gradient:$violet-gradient,
        transparent:$transparent,
        glass:$glass
);

@each $color, $value in $colors {
  .cms-color-#{$color} {
    color: $value;
  }
  .cms-back-#{$color} {
    background: $value;
  }
  .cms-hover-#{$color}:hover {
    color: $value!important;
  }
  .cms-border-#{$color} {
    border-color: $value;
  }
  .cms-fill-#{$color}{
    svg path{
      fill: $value;
    }
  }
}
