@import "../../../../styles/variables.scss";

.slicesContainer{
  background: linear-gradient(180deg, rgba(15, 22, 50, 0.70) 21.35%, rgba(44, 29, 19, 0.31) 100%);
  border-radius: 100px;
  padding: 0.25rem 0.5rem;
}

.logo{
  display: flex;
  height: 20px;
  svg{
    display: flex;
    height: 100%;
  }
}

.float{
  padding: 1rem;
  position: absolute;
  height: 60px;
  width: calc(100% - 1rem);
  top: 0.5rem;
  left: 0.5rem;
  z-index: 10;
  background: $main-background;
  border-radius: 10px;
  overflow: hidden;
}
