@import "src/styles/variables";

.profileBoard{
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 100%;
  height: 162px;
  border-radius: 15px;
  background: linear-gradient(0deg, #00125930 19%, #1D2236 100%);
  justify-content: space-around;
  align-items: center;
}

.itemContainer{
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.profileBoardItem{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  div{
    display: flex;
    width: 100%;
    justify-content: space-between;

    align-items: center;
    span{
      font-family: uberMoveBold;
      font-size: 18px;
    }
  }
  span{
    font-family: uberMoveRegular;
    font-size: 12px;
  }
}

.profileItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  div {
    display: flex;
    flex-direction: row;
    width: 100%;
    div {
      span {
        display: inline-flex;
        font-family: uberMoveRegular;
        font-size: 16px;
        padding-left: 8px;
      }
    }
  }
}

.logout{
  width: 310px;
  display: flex;
  span{
    padding-left: 8px;
  }
}

.deleteAccount{
  display: flex;
  position: absolute;
  z-index: 100;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #0C1123, #010102 100%);

  .messageBox{
    display: flex;
    flex-direction: column;
    background-color: #22201E;
    border-radius: 25px;
    margin: 20px;
    padding: 20px;
    text-align: center;
    text-justify: auto;
  }

  .titleText{
    font-family: UberMoveRegular;
    font-size: 22px;
    color: #E8EDF1;
  }

  .bodyText{
    font-size: 16px;
    line-height: 21.5px;
    margin-top: 10px;
  }
  .buttonsArea{
    display: flex;
    margin: 20px 20px 0px 20px;
    justify-content: space-between;

    .confirmDelete{
      background-color: #D21C1C;
    }
  }
}
