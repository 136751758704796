@import "../../../../../styles/variables";

.button{
  bottom:70px;
  right: 12px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $black;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.control{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background: $white;
  box-shadow: $box-shadow-button;
}
