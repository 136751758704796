@import "src/styles/variables";

.container{
  position: relative;
  width: 100%;
  height: 100%;
}

.map{
  width: 100%;
  height: 100%;
}

.controls{
  position: absolute;
  pointer-events: none;
  z-index: 100;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  >*{
    pointer-events: all;
  }
}