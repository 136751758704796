@import "src/styles/variables";

.wrapper{
    display: flex;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.slider{
    display: flex;
    gap:15px;
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}