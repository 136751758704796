.container{
  display: flex;
  flex-direction: column;
  padding: 3rem 1.5rem;
  align-items: center;
  gap: 20px;
  img{
    width: 150px;
  }
}
