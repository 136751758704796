@import "src/styles/variables";
@import "./colorThemes";

.customBtn{
  height: 38px;
  border-radius: 40px;
  padding: 0 1.5rem;
  width: max-content;
  color: $white;
  background: none;
}

.customBtn:first-letter{
  text-transform:uppercase;
}

.slideBtn{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $light-gray;
  padding: 0 1.5rem;
  border-radius: 19px;
  height: 44px;
  position: relative;
  .thumb{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 40%;
    top: 5px;
    border-radius: 19px;
    background: $primary-color;
    cursor: pointer;
    .circle{
      width: 16px;
      height: 16px;
      background: $white;
      border-radius: 8px;
    }
    .arr3, .arr4 {
      animation-name: arrowAnimation!important;
    }
    .arr1, .arr2 {
      animation-name: arrowAnimationReverse!important;
    }
    .arr2, .arr3 {
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    .arr1, .arr4 {
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
  .disableThumb{
    background: $mid-gray!important;
    .arr1, .arr2, .arr3, .arr4 {
      animation-name: none!important;
      display: none!important;
    }
  }
  p{
    margin: 0;
  }
}

@keyframes arrowAnimation{
  0%{
    opacity: 0;
    transform: translateX(-15px) rotate(180deg);
  }
  50%{
    opacity: 100;
    transform: translateX(0) rotate(180deg);
  }
  60%{
    opacity: 0;
    transform: translateX(0) rotate(180deg);
  }
  100%{
    opacity: 0;
    transform: translateX(-15px) rotate(180deg);
  }
}

@keyframes arrowAnimationReverse{
  0%{
    opacity: 0;
    transform: translateX(15px);
  }
  50%{
    opacity: 100;
    transform: translateX(0);
  }
  60%{
    opacity: 0;
    transform: translateX(0);
  }
  100%{
    opacity: 0;
    transform: translateX(15px);
  }
}


@function property($theme, $property) {
  @return map-get($theme, $property)
}
@each $theme in $themes {
  .customBtn-#{property($theme, theme)} {
    background: property($theme, background) !important;
    color: property($theme, color);
    border:1px solid property($theme, border);
    svg path{
      @if property($theme, color) != initial {
        fill: property($theme, color)!important;
      }
    }
  }
  .customBtn-#{property($theme, theme)}:hover{
    background: property($theme, hBackground) !important;
    color: property($theme, hColor) !important;
    border:1px solid property($theme, hBorder)!important;
    svg path{
      @if property($theme, hColor) != initial {
        fill: property($theme, hColor)!important;
      }
    }
  }
}
