@import "src/styles/variables";

.scanQRHeader{
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.success{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .confetti{
    position: absolute;
    width: 100%;
    top:-50px;
    z-index: 0;
  }
}
