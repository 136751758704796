@import "src/styles/variables";

.text{
  width: 263px;
  font-family: uberMoveMedium;
  text-align: center;
}

.overlay{
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
}

.card{
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background: $light-gray;
  border-radius: 35px 35px 0 0;
  min-height: 250px;
  h2{
    color: $primary-color;
  }
}

.image{
  display: flex;
  align-items: end;
  flex: 1;
  img{
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
}

.bullets{
  display: flex;
  gap:10px;
  justify-content: center;
  span{
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background: $dark-gray;
  }
}

.activeBullet{
  background: $primary-gradient!important;
  width: 8px!important;
}

.centeredBullets{
  position: absolute;  
  
  z-index: 0;
  width: 40%;
  height: 100%;
  justify-content: center;  
  align-items: center;  
}

.footerButtons{
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 10;
  right: 0px;
}

.button{
  background: $primary-gradient;
}