@import "src/styles/variables";

$themes:
        (
                theme:no-border,
                background:none,
                color:initial,
                hBackground:none,
                hColor:initial,
                border:none,
                hBorder:none
        ),
        (
                theme:primary,
                background:$primary-color,
                color:$primary-inner-text,
                hBackground:$primary-hover,
                hColor:$primary-inner-text-hover,
                border:$primary-color,
                hBorder:$primary-hover
        ),
        (
                theme:primary-outline,
                background:none,
                color:$primary-color,
                hBackground:$primary-color,
                hColor:$primary-inner-text,
                border:$primary-color,
                hBorder:$primary-color
        ),
        (
                theme:primary-gradient,
                background:$primary-gradient,
                color:$black,
                hBackground:$primary-color,
                hColor:$primary-inner-text,
                border:none,
                hBorder:none
        ),
        (
                theme:secondary,
                background:$secondary-color,
                color:$secondary-inner-text,
                hBackground:$secondary-hover,
                hColor:$secondary-inner-text-hover,
                border:$secondary-color,
                hBorder:$secondary-color
        ),
        (
                theme:secondary-outline,
                background:none,
                color:$secondary-color,
                hBackground:$secondary-color,
                hColor:$secondary-inner-text,
                border:$secondary-color,
                hBorder:$secondary-color
        ),
        (
                theme:third,
                background:$third-color,
                color:$third-inner-text,
                hBackground:$third-hover,
                hColor:$third-inner-text-hover,
                border:$third-color,
                hBorder:$third-color
        ),
        (
                theme:third-outline,
                background:none,
                color:$third-hover,
                hBackground:$third-hover,
                hColor:$third-inner-text,
                border:$third-color,
                hBorder:$third-color
        ),
        (
                theme:blue,
                background:$blue,
                color:$white,
                hBackground:$blue,
                hColor:$white,
                border:$blue,
                hBorder:$blue
        ),
        (
                theme:blue-outline,
                background:none,
                color:$blue,
                hBackground:$blue,
                hColor:$white,
                border:$blue,
                hBorder:$blue
        ),
        (
                theme:red,
                background:$red,
                color:$white,
                hBackground:$red,
                hColor:$white,
                border:$red,
                hBorder:$red
        ),
        (
                theme:red-favorites,
                background:$light-gray,
                color:$red,
                hBackground:$red,
                hColor:$red,
                border:$light-gray,
                hBorder:$red
        ),
        (
                theme:red-outline,
                background:$light-red,
                color:$red,
                hBackground:$red,
                hColor:$white,
                border:$red,
                hBorder:$red
        ),
        (
                theme:green,
                background:$green,
                color:$white,
                hBackground:$green,
                hColor:$white,
                border:$green,
                hBorder:$green
        ),
        (
                theme:green-outline,
                background:none,
                color:$green,
                hBackground:$green,
                hColor:$white,
                border:$green,
                hBorder:$green
        ),
        (
                theme:white,
                background:$white,
                color:$text-color,
                hBackground:$white,
                hColor:$text-color,
                border:$form-border,
                hBorder:$form-border
        ),
        (
                theme:white-outline,
                background:none,
                color:$white,
                hBackground:$white,
                hColor:$primary-color,
                border:$white,
                hBorder:$white
        ),
        (
                theme:light-gray,
                background:$light-gray,
                color:$text-color,
                hBackground:$primary-color,
                hColor:$primary-inner-text,
                border:none,
                hBorder:none
        ),
        (
                theme:light-gray-outline,
                background:none,
                color:$light-gray,
                hBackground:$light-gray,
                hColor:$text-color,
                border:$light-gray,
                hBorder:$light-gray
        ),
        (
                theme:confirm,
                background:$confirm-btn,
                color:$confirm-btn-inner,
                hBackground:$confirm-btn-hover,
                hColor:$confirm-btn-inner-hover,
                border:$confirm-btn,
                hBorder:$confirm-btn
        ),
        (
                theme:confirm-outline,
                background:none,
                color:$confirm-btn-inner,
                hBackground:$confirm-btn-hover,
                hColor:$confirm-btn-inner-hover,
                border:$confirm-btn,
                hBorder:$confirm-btn
        ),
        (
                theme:delete,
                background:$delete-btn,
                color:$delete-btn-inner,
                hBackground:$delete-btn-hover,
                hColor:$delete-btn-inner-hover,
                border:$delete-btn,
                hBorder:$delete-btn
        ),
        (
                theme:delete-outline,
                background:none,
                color:$delete-btn,
                hBackground:$delete-btn-hover,
                hColor:$delete-btn-inner-hover,
                border:$delete-btn,
                hBorder:$delete-btn
        ),
        (
                theme:disabled,
                background:$disable-color,
                color:$disable-inner-color,
                hBackground:$disable-color,
                hColor:$disable-inner-color,
                border:$disable-color,
                hBorder:$disable-color
        ),
        (
                theme:disabled-outline,
                background:none,
                color:$disable-color,
                hBackground:none,
                hColor:$disable-color,
                border:$disable-color,
                hBorder:$disable-color
        ),(
                theme:violet-gradient,
                background:$violet-gradient,
                color:$black,
                hBackground:$primary-color,
                hColor:$primary-inner-text,
                border:none,
                hBorder:none
        );