@import 'src/styles/variables';
@import 'src/styles/globals';

.container{
	display: flex;
	flex-direction: row-reverse;
	flex: 1;
	height: 100svh;
	overflow: hidden;
}

.contentBack{
	display: flex;
	padding-left: 85px;
	height: 100%;
	width:100%;
	background: $main-background;
}
.content{
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.nav {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 81px;
	height: 100%;
	padding: 7px 30px;
	z-index: 20;
	left:0;
	background: transparent;
}

.navbarBackLeft {
	position: absolute;
	height: calc(50% - 97px);
	width: 84px;
	left: 0;
	bottom: 20px;
	background: #1d1d29;
	border-radius:  0 0 25px 0;
}

.navbarBackRight {
	position: absolute;
	height: calc(50% - 62px);
	width: 84px;
	left: 0;
	top:20px;
	background: #1d1d29;
	border-radius: 0 25px 0 0;
}

.navBackground{
	position: absolute;
	width: 120px;
	height: 85px;
	bottom:calc(50% - 60px);
	left:50%;
	transform: translateX(-50%) rotate(90deg);
}

.navGameBtn{
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width:81px;
	height: 74px;
	bottom:calc(50% - 55px);
	left:75px;
	transform: translateX(-50%) rotate(90deg);
	div{
		padding-bottom: 10px;
	}
	img{
		position: absolute;
		height: 74px;
		bottom:40px;
		top: 0;
		left: 0;
		z-index: -1;
	}
}

.button{
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	flex:0.2;
	p{
		color: $dark-gray;
		text-align: center;
	}
	:hover{
		@extend .primary-gradient-text;
	}
}

.navDivider{
	width: 30px;
	border-top: 1px solid $form-border;
	margin: 4px 0;
}

@media only screen and (max-width: 768px) {
	.container{
		flex-direction: column;
	}
	.content{
		display: flex;
		flex-direction: column;
		width: 100%;
		height: calc(100% - 85px);
	}
	.contentBack{
		flex: initial;
		padding-left: initial;
	}
	.nav {
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		height: 85px;
		padding: 7px 30px;
		bottom: 0;
	}
	.navDivider{
		width: 0;
		border-top: none;
		height: 30px;
		margin: initial;
	}
	.navbarBackLeft {
		position: absolute;
		height: 85px;
		width: calc(50% - 60px);
		left: 0;
		right: initial;
		bottom: initial;
		top: initial;
		background: #1d1d29;
		border-radius: 25px 0 0 0;
	}

	.navbarBackRight {
		position: absolute;
		height: 85px;
		width: calc(50% - 60px);
		right: 0;
		left: initial;
		bottom: initial;
		top: initial;
		background: #1d1d29;
		border-radius: 0 25px 0 0;
	}

	.navBackground{
		position: absolute;
		width: 120px;
		height: 85px;
		bottom:0;
		left:50%;
		transform: translateX(-50%);
	}

	.navGameBtn{
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		width:81px;
		height: 74px;
		bottom:40px;
		left:50%;
		transform: translateX(-50%);
		div{
			padding-bottom: 10px;
		}
		img{
			position: absolute;
			height: 74px;
			bottom:40px;
			top: 0;
			left: 0;
			z-index: -1;
		}
	}
}

.cartAlert{
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 18px;
	height: 18px;
	top:50%;
	left: 50%;
	transform: translate(5px, -30px);
	border-radius: 9px;
	background: $primary-gradient;
	z-index: 1000;
}
