@import "src/styles/variables";

.container{
  //transform:translateY(50%);
  background: $white;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo{
  width: 50px;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
}

.pattern{
  width: 150px;
}
