@import "./variables";
@import "./colors";
@import "./fontSizes";
@import './lightColors.scss';
@import './darkColors.scss';

@font-face { font-family: "uberMoveLight";
  src: url("../assets/fonts/UberMove-Light.woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face { font-family: "uberMoveRegular";
  src: url("../assets/fonts/UberMove-Regular.woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face { font-family: "uberMoveMedium";
  src: url("../assets/fonts/UberMove-Medium.woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face { font-family: "uberMoveBold";
  src: url("../assets/fonts/UberMove-Bold.woff2");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  // overscroll-behavior-y: contain;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input, label, textarea, button {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  font-family: "uberMoveRegular", sans-serif;
}

.portals{
  position: fixed;
  z-index: 1002;
  -webkit-transition: 0s;
  transition: 0s;
  > * {
    -webkit-transition: 0s;
    transition: 0s;
  }
}

.bottomSheets{
  position: fixed;
  z-index: 1003;
  -webkit-transition: 0s;
  transition: 0s;
  > * {
    -webkit-transition: 0s;
    transition: 0s;
  }
}
.selectSheet{
  z-index: 1004;
}

a,p,li,h1,h2,h3,div,input,select,textarea{color:var(--cms-text-color)}

textarea:focus, input:focus, button:focus{
  outline: none;
}

input[type="radio"] {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  border:4px solid $white;
}

input[type="radio"]:after {
  content: "";
  position: absolute;
  border: 1px solid $primary-color;
  height: 14px;
  width: 14px;
  border-radius: 7px;
}

input[type="radio"]:not(:checked) {
  background-color: transparent;
}

input[type="radio"]:checked {
  background-color: $primary-color;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
ul {
  list-style:none;
  margin: 0;
  padding: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a{
  text-decoration: none!important;
  cursor: pointer;
}

h1, h2, h3, th, .bold{
  font-family: "uberMoveBold", sans-serif;
}
h1{font-size: 1.1rem}
h2{font-size: 1rem}
h3{font-size: 0.9rem}
a,p,li,td,th,input,textarea,select,button, label, .app-toast{font-size: 0.9rem}

.border-bottom{
  border-bottom: 1px solid $form-border;
}

.cornerStyle-large{
  border-radius: 100px;
}

.cornerStyle-small{
  border-radius: 20px;
}

@media (max-width: 767px) {
  ::-webkit-scrollbar-thumb {
    border-left: none!important;
    background-color:transparent!important;
  }
  ::-webkit-scrollbar {
    width: 0!important;
    height: 0!important;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-left: 6px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: #AAAAAA;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}


.slick-track {
  height: 100%;
}
.slick-list , .slick-dots {
  direction: ltr;
}
.slick-slider {
  display: flex !important;
  flex-direction: column !important;
  height: 100%;
}
.slick-dots {
  position: relative !important;
  bottom: unset !important;
}
.slick-dots li {
  width: 10px !important;
}
.slick-slide > div {
  padding: 0 !important;
  width: 100%;
  height: 100%;
}
.slick-slide.slick-center img{
  border-bottom: 2px solid $primary-color;
  padding-bottom: 8px;
}
.slick-slide {
  display: flex !important;
  align-items: center;
  padding: $slick-slide-padding;
}
.slick-slider{
  .slick-arrow{
    display:flex !important;
  }
}

.rmdp-container{
  width: 100%;
}

.rmdp-container > * {
  -webkit-transition: 0s!important;
  transition: 0s!important;
}

.ep-arrow{
  -webkit-transition: .2s;
  transition: .2s;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.form-hint{
  color: $red;
  text-align: right;
  font-size: 0.7rem;
}

.price-tag{
  position: absolute;
  width: max-content;
  .textContent{
    position: absolute;
    display: flex;
    flex-direction: column;
    background: $white;
    padding: 10px;
    border-radius: 5px;
    border:1px solid black;
    bottom:31px;
    left:-21px;
    pointer-events: all;
    p{
      font-size: 15px;
      font-weight: bold;
      white-space: nowrap;
    }
  }

  .avatar-tag{
    transform: translateY(-50%);
  }

  .pinTop, .pinMask{
    position: absolute;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    border:1px solid black;
    bottom:23px;
    z-index: -1;
    background: $white;
    left:-9px;
  }

  .pinMask{
    border:none!important;
    z-index: 1!important;
    bottom:24px!important;
    border-radius: 20px 0 0 0;
  }

  .pinBottom{
    position: absolute;
    width: 0;
    height: 20px;
    border-left:1px solid black;
    bottom:0;
    z-index: 0;
    left:0;
  }
}

.primary-gradient-text {
  background: $primary-gradient;
  -webkit-background-clip: text;
  color: transparent!important;
}
.primary-gradient {
  background: $primary-gradient!important;
}

.gradient {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, $light-gray 8%, #e7e7e7  38%, $light-gray 54%);
  background-size: 1000px 640px;

  position: relative;

}

@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}

.reader video{
  width: 100%!important;
}



