@import "src/styles/variables";

.horizontal{
  width: 100%;
  height: 0;
  border-top: 1px solid $form-border;
}

.vertical{
  height: 100%;
  width: 0;
  border-top: 1px solid $form-border;
}