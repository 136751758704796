@import "./variables";

$fontSizes : (
        f-s-1:0.7,
        f-s-2:0.8,
        f-s-3:0.9,
        f-s-4:1,
        f-s-5:1.1,
        f-s-6:1.2,
        f-s-7:1.3,
        f-s-8:1.4,
);

@each $size, $value in $fontSizes {
  .#{$size} {
    font-size: #{$value}rem!important;
  }
}